@import '../styles/colors';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.input {
  width: 100%;
  margin-bottom: 20px;
}

.error {
  color: red;
}

.avatar {
  margin: 10px;
  background-color: $primary;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.typography {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 32px;
}

.fullWidth {
  width: 100%;
}
