$primary: #548c3f;
$secondary: #215593;
$shaded: #e4e4e4;
$background: #f2f2f2;
$darkGrey: #919191;
$error: #e03157;
$warning: #fc9824;
$info: #3097ef;
$white: #ffffff;
$black: #000000;

// Relationship Colors
$daugherty: #548c3f;
$nonDaugherty: #4c70ae;
$directEmployee: #737373;
