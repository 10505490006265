body {
  overflow: hidden;
  background-color: #fafafa;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.tree-body {
  margin: 0 auto;
}

/*temporary for building hierarchy tree*/
.MuiTreeItem-label {
  width: auto !important;
}

.MuiTreeItem-root:focus > .MuiTreeItem-content {
  background-color: inherit !important;
}

.NodeWithChildren {
  cursor: pointer !important;
}

.NodeBorder:hover {
  border: 2px solid #548c3f;
}

.orgChartNode {
  min-width: 250px;
  max-width: 300px;
  height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orgchartnode:hover {
  zoom: 2 !important;
  box-shadow: 120px 80px 40px 20px goldenrod;
}

table.orgChartNode {
  text-align: left;
}

.orgChartNodeOuter {
  border: 2px solid #dcdcdc;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.google-visualization-orgchart-node-medium.nodeCell {
  border: 0px;
  box-shadow: none;
  padding-top: 0px;
  vertical-align: top;
}

.google-visualization-orgchart-node-medium.nodeCell.flaggedForExtension {
  max-width: 40px;
}

.selectedOrgChartNodeOuter {
  border: 2px solid#dcdcdc;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.google-visualization-orgchart-linebottom {
  border-bottom: 1px solid #548c3f !important;
}

.google-visualization-orgchart-lineleft {
  border-left: 1px solid #548c3f !important;
}

td.google-visualization-orgchart-lineright {
  padding-right: 3px;
  border-right: 1px solid #548c3f !important;
}

.MuiCardContent-root:last-child {
  padding-bottom: 0px !important;
}

.MuiCardContent-root {
  padding: 0px !important;
}

.client-grid {
  margin: -2px 0 !important;
}

/* Unfavorable */
.RelationshipRed {
  background-color: #f6c76f !important;
}
/* Neutral */
.RelationshipYellow {
  background-color: #dedede !important;
}
/* Favorable */
.RelationshipLime {
  background-color: #9eb7dc !important;
}
/* Trusted Advisor */
.RelationshipLightGreen {
  color: #040404;
  background-color: #b3e06e !important;
}
/* Unused?? */
/* .RelationshipGreen {
  background-color: #548c3f !important;
} */
/* Target */
.RelationshipBlue {
  background-color: #c5bedd !important;
}

.reportCount {
  color: #000000;
  font-size: 1rem;
  width: 4rem;
  border-radius: 0.5rem;
  margin: 0 2px 0 2px !important;
}

.footer {
  position: fixed;
  bottom: 4rem;
  right: 1rem;
}

#chart-wrapper {
  height: 100%;
  width: 100%;
}
#chart-wrapper div[style*='transform-origin'] {
  transform-origin: center !important;
}

span[class$='-png'] {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.salesforce-png {
  background-image: url('images/salesforce.png');
  margin: 0 4px;
}

.linkedin-png {
  background-image: url('images/linkedin_icon.png');
  margin: 0 4px;
}

/*****************************
  Daugherty Org Chart Styles
*****************************/
.d-org-chart-wrapper {
  width: 100%;
  height: calc(100% - 185px);
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  gap: 10px;
  padding-bottom: 100px;
}

svg.org-chart-svg {
  overflow: visible;
  position: relative;
  margin: 0px;
  z-index: -1;
  width: 100%;
  height: 5px;
}

.d-node[data-nodetype='Employee'] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 10px;
  padding: 4px;
  width: 225px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  z-index: 0;
}

.d-node[data-nodetype='DbsConsultant'] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 10px;
  padding: 0px;
  width: 225px;
  text-align: center;
  box-sizing: border-box;
  z-index: 0;
}

.d-node.root-node {
  margin: 0px auto;
}

.social-icons {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.node-wrapper {
  flex-direction: column;
  margin: 0px auto;
}

.node-wrapper .child-node {
  margin: 10px auto;
}

.node-wrapper .d-org-chart-wrapper .d-org-chart-wrapper {
  height: auto;
}
.node-has-children {
  order: 9999;
}

.green-border:hover {
  border: 1px solid #548c3f;
}

.node-show-children {
  flex-grow: 2;
}

.employee-name {
  font-size: 0.8em;
  font-weight: bold;
  margin: 0 0 4px 0;
}

.employee-title {
  font-size: 0.7em;
  font-style: italic;
  flex-grow: 1;
  margin: 0;
}

.employee-department {
  font-size: 0.7em;
  flex-grow: 1;
  margin: 0;
}

.last-contact-date {
  font-size: 0.7em;
  flex-grow: 1;
  margin: 0;
}

.employee-info-container {
  min-height: 4.5em;
  padding-top: 0.4em;
  margin-top: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;
  padding-bottom: 14px;
  position: relative;
}

.node-footer-container[data-nodetype='Employee'] {
  display: flex;
  justify-content: space-evenly;
  font-size: 1rem;
  margin-bottom: 14px;
}

.node-footer-container[data-nodetype='DbsConsultant'] {
  display: flex;
  justify-content: space-evenly;
  font-size: 1rem;
  z-index: 1;
  text-align: center;
  height: 0;
}

.node-footer-counters {
  display: flex;
  align-self: flex-end;
}

.node-subordinate-indicator[data-nodetype='Employee'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 32px);
}

.node-subordinate-indicator[data-nodetype='DbsConsultant'] {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  bottom: 4px;
  width: 0;
}

.org-nav-icon {
  display: flex;
  width: 26px;
  height: 26px;
  background-size: 26px;
  background-repeat: no-repeat;
  background-image: url('images/icon_org_nav.png');
  margin: 0 4px;
}

.drill-down-icon {
  display: flex;
  width: 14px;
  height: 6px;
  background-size: 14px;
  background-repeat: no-repeat;
  background-image: url('images/icon_drill_down.png');
  margin: 4px 4px;
}

.ag-header-cell-label {
  color: #000000;
}

.ag-grid-cell-error {
  border: 1px solid #e03157 !important;
}
