@use './styles/colors';

hr {
  padding: 0;
  margin: 0;
  color: colors.$black;
}

.reports {
  color: colors.$white;

  &.pills {
    width: 3.5rem;
    font-size: 0.9em;
    border-radius: 1rem;
    margin: 0 2px 0 2px !important;
  }

  &.daugherty-count {
    background: colors.$daugherty;
  }

  &.non-daugherty-count {
    background: colors.$nonDaugherty;
  }

  &.direct-count {
    background: colors.$directEmployee;
  }
}
